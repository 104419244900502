import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";


export const Sitesettings = () => {

  const [WebTitle, setWebTitle] = useState("");
  const [WebsiteName, setWebName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyWebsite, setCompanyWebsite] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");

  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [poster1, setPoster1] = useState("");
  const [poster2, setPoster2] = useState("");
  const [qrcode, setQrcode] = useState("");

  const [refcom, setrefcom] = useState("");
  const [telegram, settelegram] = useState("");
  const [insta, setinsta] = useState("");
  const [webmsg, setwebmsg] = useState("");
  const [ytlink, setytlink] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);
  const [commission, setCommission] = useState('');
  const [upiid, setupiid] = useState('');

  const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "settings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId((res.data._id) ? res.data._id : '');
      setWebTitle(res.data.WebTitle)
      setWebName(res.data.WebsiteName);
      setCompanyName(res.data.CompanyName);
      setCompanyAddress(res.data.CompanyAddress);
      setCompanyMobile(res.data.CompanyMobile);
      setCompanyEmail(res.data.CompanyEmail);
      setCompanyWebsite(res.data.CompanyWebsite);
      setLogo(res.data.Logo);
      setSmallLogo(res.data.SmallLogo);
      setLandingImage1(res.data.LandingImage1);
      setLandingImage2(res.data.LandingImage2);
      setLandingImage3(res.data.LandingImage3);
      setLandingImage4(res.data.LandingImage4);
      setytlink(res.data.ytlink);
      
      setPoster1(res.data.poster1);
      setPoster2(res.data.poster2);
      setQrcode(res.data.qrcode);
      
      issetLandingImage1(res.data.isLandingImage1);
      issetLandingImage2(res.data.isLandingImage2);
      issetLandingImage3(res.data.isLandingImage3);
      issetLandingImage4(res.data.isLandingImage4);
      setVersion(res.data.version)
        setCommission(res.data.commission);
        setupiid(res.data.upiid);
        
    setinsta(res.data.insta);
    settelegram(res.data.telegram);
    setwebmsg(res.data.websitemsg);
    setrefcom(res.data.refcom);
    
    });
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("WebTitle", WebTitle);
    formData.append("WebsiteName", WebsiteName);
    formData.append("CompanyName", CompanyName);
    formData.append("CompanyAddress", CompanyAddress);
    formData.append("CompanyMobile", CompanyMobile);
    formData.append("CompanyEmail", CompanyEmail);
    formData.append("CompanyWebsite", CompanyWebsite);
    formData.append("Logo", Logo);
    formData.append("SmallLogo", SmallLogo);
    formData.append("LandingImage1", LandingImage1);
    formData.append("LandingImage2", LandingImage2);
    formData.append("LandingImage3", LandingImage3);
    formData.append("LandingImage4", LandingImage4);

    formData.append("poster1", poster1);
    formData.append("poster2", poster2);
    formData.append("qrcode", qrcode);

    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("version", version);
    formData.append("commission", commission);

    formData.append("upiid", upiid);
    formData.append("ytlink", ytlink);
    formData.append("websitemsg", webmsg);
    formData.append("insta",insta);
    formData.append("telegram", telegram);
    formData.append("refcom", refcom);

    const response = await axios.post(
      baseUrl + `settings`,
      formData
    );
    console.log(response.data.status);
    if(response.data.status==='success'){
      alert("Settings submitted successfully");
    }else{
      alert("Settings Not Submitted");
    }
  };




  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    const poster1 = document.getElementById("poster1");
    const poster2 = document.getElementById("poster2");
    const qrcode = document.getElementById("qrcode");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };

    poster1.onchange = (e) => {
      const [file] = poster1.files;
      setPoster1(file);
    };
    poster2.onchange = (e) => {
      const [file] = poster2.files;
      setPoster2(file);
    };
    qrcode.onchange = (e) => {
      const [file] = qrcode.files;
      setQrcode(file);
    };

  }, []);

  return (
    <>
      <h3 className="text-uppercase font-weight-bold my-3 text-white">Website Settings</h3>

      <h4 className="text-uppercase font-weight-bold my-3 text-light" >UI Settings</h4>
      <form
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
        style={{backgroundColor:"rgba(0, 27, 11, 0.734)"}}
      >
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Website Title</label>
            <input
              className="form-control"
              type="text"
              value={WebTitle}
              onChange={(e) => setWebTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Website Name</label>
            <input
              className="form-control"
              type="text"
              value={WebsiteName}
              onChange={(e) => setWebName(e.target.value)}
            />
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Name</label>
            <input
              className="form-control"
              type="text"
              value={CompanyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Address</label>
            <input
              className="form-control"
              type="text"
              value={CompanyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>
        
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Mobile</label>
            <input
              className="form-control"
              type="text"
              value={CompanyMobile}
              onChange={(e) => setCompanyMobile(e.target.value)}
            />
          </div>
        
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteEmail">Commpany Email</label>
            <input
              className="form-control"
              type="text"
              value={CompanyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
            />
          </div>
        
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Commpany Website</label>
            <input
              className="form-control"
              type="text"
              value={CompanyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">commission</label>
            <input
              className="form-control"
              type="text"
              value={commission}
              onChange={(e) => setCommission(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Right Logo</label>
            <input className="form-control" type="file" name="Logo" id="Logo"  />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Left Logo</label>
            <input className="form-control" type="file" name="SmallLogo" id="SmallLogo"  />

          </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Game image (1) </label>
            <input className="form-control" type="file" name="LandingImage1" id="LandingImage1"  />
            <select className="form-control" name="" id="" value={isLandingImage1} onChange={(e) => issetLandingImage1(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (2)</label>
            <input className="form-control" type="file" name="LandingImage2" id="LandingImage2"  />
            <select className="form-control" name="" id="" value={isLandingImage2} onChange={(e) => issetLandingImage2(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (3)</label>
            <input className="form-control" type="file" name="LandingImage3" id="LandingImage3"  />
            <select className="form-control" name="" id="" value={isLandingImage3} onChange={(e) => issetLandingImage3(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (2)</label>
            <input className="form-control" type="file" name="LandingImage4" id="LandingImage4"  />
            <select className="form-control" name="" id="" value={isLandingImage4} onChange={(e) => issetLandingImage4(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Poster 1</label>
            <input className="form-control" type="file" name="poster1" id="poster1"  />

          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Poster 2</label>
            <input className="form-control" type="file" name="poster2" id="poster2"  />

          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Qrcode</label>
            <input className="form-control" type="file" name="qrcode" id="qrcode"  />

          </div>
        </div>


        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">version</label>

            <input
              className="form-control"
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </div>


        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Instagram</label>

            <input
              className="form-control"
              type="text"
              value={insta}
              onChange={(e) => setinsta(e.target.value)}
            />
          </div>


        </div>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">refreal com</label>

            <input
              className="form-control"
              type="text"
              value={refcom}
              onChange={(e) => setrefcom(e.target.value)}
            />
          </div>


        </div>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">telegram</label>

            <input
              className="form-control"
              type="text"
              value={telegram}
              onChange={(e) => settelegram(e.target.value)}
            />
          </div>


        </div>
        
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Website msg</label>

            <input
              className="form-control"
              type="text"
              value={webmsg}
              onChange={(e) => setwebmsg(e.target.value)}
            />
          </div>


        </div>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Youtube Link</label>

            <input
              className="form-control"
              type="text"
              value={ytlink}
              onChange={(e) => setytlink(e.target.value)}
            />
          </div>


        </div>
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">UPI ID</label>

            <input
              className="form-control"
              type="text"
              value={upiid}
              onChange={(e) => setupiid(e.target.value)}
            />
          </div>


        </div>


        <div className="form-row">

          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">submit</button>

          </div>
        </div>


      </form>
      <Gateway />
    </>
  );
};
